/* stylelint-disable selector-pseudo-class-no-unknown, no-descending-specificity */

@import '~styles/mixins';

.chartOuterWrapper {
  position: relative;
  display: grid;
  width: 100%;
  min-width: 0;
}

.btnUpdate {
  position: absolute;
  top: -30px;
  right: 0;
}

.chartContainer {
  width: 100%;
  min-width: 0;
  max-width: 1096px;
  height: 100%;
  min-height: 450px;
  max-height: 800px;
  overflow: visible !important;

  @include b-d(md) {
    min-height: 800px;
    max-height: 800px;
  }

  :global(.highcharts-breadcrumbs-button > text) {
    transition: opacity 0.25s;

    &:hover {
      opacity: 0.8;
    }
  }

  :global(div.highcharts-data-label) {
    display: flex;
    align-items: center;
    padding: 8px 12px;
    color: $primary;
    background-color: $gray-900;
    border-radius: 100px;

    > span {
      position: static !important;
    }

    @include b-d(md) {
      padding: 6px 10px;
      border-radius: 100px;
    }
  }

  :global(.highcharts-internal-node-interactive) {
    opacity: 1;
    transition: opacity 0.25s;

    &:hover {
      opacity: 0.75;
    }
  }
}
