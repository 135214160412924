@import '~styles/mixins';

.layout {
  display: grid;
  grid-template-columns: 248px 1fr;
  row-gap: 24px;
  column-gap: 32px;
  min-width: 375px;
  min-height: 100vh;

  @include b-d(xxl) {
    column-gap: 16px;
  }

  @include b-btw(lg, xxl) {
    grid-template-columns: 234px 1fr;
  }

  @include b-d(lg) {
    grid-template-rows: auto 1fr;
    grid-template-columns: 1fr;
    row-gap: 24px;
  }
}

.copyright {
  @include b-up(lg) {
    display: none;
  }
}
