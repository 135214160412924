.offcanvas {
  $this: &;

  &-bottom {
    border-radius: 16px 16px 0 0;

    #{$this}-header {
      padding: 16px;
      border-bottom: 1px solid $white-10;
    }

    #{$this}-btn-close {
      padding: 6px;
      background-color: $gray-700;
      border-radius: 8px;
      transition: background-color 0.15s;

      &:hover {
        background-color: $gray-600;
      }
    }

    #{$this}-body {
      display: grid;
      row-gap: 24px;
      padding-bottom: 44px;
    }
  }

  &-backdrop {
    transition: opacity $offcanvas-transition-duration,
      backdrop-filter $offcanvas-transition-duration;

    &.show {
      backdrop-filter: blur(6px);
    }

    // Mozilla Firefox
    @supports not (backdrop-filter: blur(6px)) {
      background-color: rgba($gray-900, 0.6);
    }
  }
}
