.container {
  /* stylelint-disable-next-line scss/at-extend-no-missing-placeholder */
  @extend .container-fluid;

  max-width: 1440px;

  &-fluid {
    padding: $container-padding-top-xxl $container-padding-x-xxl
      $container-padding-bottom-xxl;
    margin: auto;

    @include b-btw(lg, xxl) {
      padding: $container-padding-top-lg $container-padding-x-lg
        $container-padding-bottom-lg;
    }

    @include b-d(lg) {
      padding: $container-padding-top-md $container-padding-x-md
        $container-padding-bottom-md;
    }
  }
}
