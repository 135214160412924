@import '~styles/mixins';

.nftCard {
  display: grid;
  grid-template-columns: auto;
  padding: 12px 12px 16px;
  background-color: $gray-900;
  border-radius: 16px;

  @include b-up(md) {
    align-content: space-between;
  }

  @include b-d(md) {
    grid-template-columns: 92px 1fr;
    align-items: start;
    padding: 12px;
  }
}

.nftImg {
  border-radius: 12px;
  object-fit: cover;
}

.nftInfo {
  display: grid;
  grid-auto-flow: row;
  row-gap: 8px;
  margin-top: 16px;

  &_title {
    margin-bottom: 4px;
  }

  @include b-d(md) {
    margin: 2px 4px 2px 16px;
  }
}

.infoItem {
  display: flex;
  align-items: center;
  justify-content: space-between;

  &_title {
    color: $white-50;
  }

  &_value {
    font-weight: $font-weight-semi-bold;
  }
}

.itemWithIcon {
  display: grid;
  grid-template-columns: auto auto;
  gap: 4px;
  align-items: center;
}

.placeholderItem {
  height: 20px;
}

.placeholderPadding {
  box-sizing: content-box;
  padding: 2px 0;
}
