// h1, h2, h3, p etc.

h1 {
  @include b-d(md) {
    font-size: 20px;
    font-weight: $font-weight-bolder;
    line-height: calc(24 / 20);
  }
}

h3 {
  font-weight: $font-weight-bolder;
  line-height: calc(24 / 20);
}

h5 {
  font-weight: $font-weight-medium;
  line-height: calc(20 / 18);
}

.lead {
  line-height: calc(20 / 18);
}

.text-gradient-primary {
  @include text-gradient-primary;
}
