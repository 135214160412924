@import '~styles/mixins';

.modalWrapper {
  display: grid;
  grid-template-rows: 1fr auto;
  gap: 8px;
  height: 100%;
  padding-top: 58px;
}

.menuWrapper {
  @include hideScrollbar;

  padding: 16px 16px 0;
  overflow-y: auto;
}

.menuFooter {
  display: grid;
  grid-auto-flow: column;
  gap: 24px;
  justify-content: center;
  padding: 10px 16px 24px 10px;
  margin: 0;

  &_item {
    display: flex;
    justify-content: center;
  }

  &_link {
    color: $white-50;
    text-decoration: none;

    &:hover {
      color: $white;
    }
  }
}
