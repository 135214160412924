@import '~styles/mixins';

$btn-paddings: 2px 2px 2px 4px;

.tableHeader {
  visibility: visible;

  th:last-child button {
    margin-left: auto;
  }
}

.padding {
  padding: $btn-paddings;
}

.btn {
  display: grid;
  grid-auto-flow: column;
  column-gap: 4px;
  align-items: center;
  padding: $btn-paddings;
  border-radius: 4px;
  transition: background-color 0.25s;

  &::after {
    display: block;
    width: 12px;
    height: 12px;
    visibility: hidden;
    content: '';
    background-image: url("data:image/svg+xml,%3Csvg width='12' height='12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M6.87 8.48a1 1 0 0 1-1.74 0L2.85 4.5A1 1 0 0 1 3.72 3h4.56a1 1 0 0 1 .87 1.5L6.87 8.48Z' fill='%23fff' fill-opacity='.5'/%3E%3C/svg%3E%0A");
    opacity: 0;
    transition: all 0.25s;
  }

  &:hover {
    background-color: $white-10;

    &::after {
      visibility: visible;
      opacity: 1;
    }
  }

  &.desc,
  &.asc {
    &::after {
      visibility: visible;
      background-image: url("data:image/svg+xml,%3Csvg width='12' height='12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M6.87 8.48a1 1 0 0 1-1.74 0L2.85 4.5A1 1 0 0 1 3.72 3h4.56a1 1 0 0 1 .87 1.5L6.87 8.48Z' fill='url(%23a)'/%3E%3Cdefs%3E%3ClinearGradient id='a' x1='10' y1='10' x2='1.63' y2='9.53' gradientUnits='userSpaceOnUse'%3E%3Cstop stop-color='%23C4FDA7'/%3E%3Cstop offset='1' stop-color='%23A6FCF0'/%3E%3C/linearGradient%3E%3C/defs%3E%3C/svg%3E%0A");
      opacity: 1;
    }
  }

  &.asc {
    &::after {
      transform: rotateX(180deg);
    }
  }
}
