@import '~styles/mixins';

.modalWrapper {
  display: grid;
  grid-template-rows: 1fr auto;
  gap: 32px;
  min-height: 0;
  max-height: 100%;

  @include b-btw(md, lg) {
    gap: 24px;
  }

  @include b-d(md) {
    gap: 16px;
  }
}

.modalContent {
  display: grid;
  grid-template-rows: auto auto 1fr;
  gap: 13px;
  height: 100%;
  min-height: 0;
  padding: 29px 24px;
}

.modalTitle {
  display: grid;
  grid-template-columns: auto 1fr;
  gap: 8px;
  align-items: center;
  text-transform: capitalize;

  &_img {
    width: 32px;
    height: 32px;
    border-radius: 16px;
  }
}

.tableWrapper {
  height: 100%;
  padding-right: 14px;
  overflow-y: auto;

  /* For FireFox */
  scrollbar-width: thin;

  &::-webkit-scrollbar {
    width: 4px;
    border-radius: 2px;

    &-thumb {
      background-color: $white-50;

      &:hover {
        background-color: rgba($white, 0.6);
      }
    }
  }
}

.table {
  height: fit-content;
  font-size: inherit;
  font-weight: $font-weight-medium;
  line-height: inherit;

  th {
    padding-top: 3px;
  }
}

.cell {
  display: grid;
  grid-auto-flow: row;
  gap: 8px;
}

.closeButton {
  justify-self: center;
  padding: 12px 24px;
  color: $gray-900;
  background: $gradient-primary;
  border-radius: 24px;

  svg {
    transition: transform 0.25s;
  }

  &:hover {
    svg {
      transform: rotate(90deg);
    }
  }
}
