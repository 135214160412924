@import '~styles/mixins';

.colImg {
  display: grid;
  grid-template-columns: 40px auto;
  column-gap: 16px;
  align-items: center;
}

.img {
  height: 40px;
  border-radius: 8px;

  @include b-up(xxl) {
    width: 48px;
    height: 48px;
  }
}
