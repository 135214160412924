@import '~styles/mixins';

.paginator {
  display: grid;
  grid-auto-flow: column;
  column-gap: 4px;
  align-items: center;
  justify-content: center;
  margin: 0;
  list-style-type: none;
  user-select: none;
}

.spacer {
  margin-top: 24px;
}

.pageLink {
  position: relative;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 48px;
  height: 48px;
  color: $white;
  background-color: $gray-900;
  background-repeat: no-repeat;
  background-position: center;
  border-radius: 8px;
  transition-duration: 0.25s, 0s;
  transition-property: background-color, color;

  @include b-d(md) {
    width: 38px;
    height: 38px;
  }

  &::before {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
    content: '';
    background: $gradient-primary;
    border-radius: inherit;
    opacity: 0;
  }

  &:hover {
    color: $white;
    background-color: $gray-600;
  }

  &.active {
    color: $gray-900;

    &::before {
      opacity: 1;
    }

    &:hover {
      color: $gray-900;
    }
  }

  &.previousLink {
    > svg {
      transform: rotateY(180deg);
    }
  }

  &.toStartLink {
    > svg {
      transform: rotateY(180deg);
    }

    @include b-d(md) {
      display: none;
    }
  }

  &.toEndLink {
    @include b-d(md) {
      display: none;
    }
  }
}

.pageBreak {
  > .pageLink {
    align-items: end;
    padding-bottom: 4px;
    background-color: transparent;
  }
}

.disabledLink {
  color: $gray-600;
  cursor: default;

  &:hover {
    color: $gray-600;
    background-color: $gray-900;
  }
}
