@import '~styles/mixins';

.menuItem {
  display: grid;
  grid-template-columns: auto 1fr;
  gap: 8px;
  align-items: center;
  padding: 12px 16px;
  color: $white;
  text-decoration: none;
  background-color: $gray-900;
  border-radius: 8px;

  &:hover {
    color: $white;
    background-color: $gray-800;
  }

  &.active {
    color: $gray-900;
    pointer-events: none;
    background: $gradient-primary;
  }

  @include b-d(lg) {
    background-color: $gray-800;
  }
}
