@import '~styles/mixins';

.inputSearch {
  display: grid;
  grid-template-columns: 1fr auto;
  column-gap: 8px;
  align-items: center;
}

.btnClear {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px 14px;
  color: $white;
  background-color: $input-bg;
  border: 1px solid $input-bg;
  border-radius: 8px;
  transition: color 0.25s, border-color 0.25s;

  &:hover {
    border-color: mix($white-50, $input-bg);
  }

  &:disabled {
    color: $gray-600;

    &:hover {
      border-color: mix($white-10, $input-bg);
    }
  }
}
