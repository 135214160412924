@import '~styles/mixins';

.footer {
  display: grid;
  row-gap: 24px;
}

.footerNav {
  display: grid;
  grid-auto-flow: column;
  padding: 10px 0;
  margin: 0;

  &_item {
    display: flex;
    justify-content: center;
  }

  &_link {
    color: $white-50;
    text-decoration: none;

    &:hover {
      color: $white;
    }
  }
}
