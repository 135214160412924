@import '~styles/mixins';

.cardsWrapper {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 16px;
  margin: 12px 0 24px;

  @include b-d(xxl) {
    grid-template-columns: repeat(3, 1fr);
  }

  @include b-d(md) {
    grid-template-columns: 1fr;
  }
}
