// Rotate animation
@keyframes rotate {
  100% {
    transform: rotate(1turn);
  }
}

// Rotate animation
@keyframes rotate-reverse {
  100% {
    transform: rotate(-1turn);
  }
}

// "..." animation for loading
@keyframes dotty {
  0% {
    content: '';
  }

  25% {
    content: '.';
  }

  50% {
    content: '..';
  }

  75% {
    content: '...';
  }

  100% {
    content: '';
  }
}
