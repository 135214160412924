button {
  display: block;
  padding: 0;
  font-weight: inherit;
  color: inherit;
  background: none;
  border: 0;
  outline: none;
  appearance: none;
}
