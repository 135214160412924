@import '~styles/mixins';

.sectionCurrenciesHeld {
  display: grid;
  grid-template-rows: auto 1fr;
  row-gap: 48px;
  height: 100%;
}

.chartContainer {
  @include b-d(md) {
    min-height: 1000px;
    max-height: 1000px;
  }
}
