/* stylelint-disable no-descending-specificity */
@import '~styles/mixins';

.tableWrapper {
  @include b-up(xxl) {
    margin-top: 20px;
  }

  @include b-d(lg) {
    @include hideScrollbar;

    width: 100vw;
    margin: 0 -16px;
  }

  @include b-btw(md, lg) {
    margin-top: 4px;

    &.fadeOutRightEdge {
      mask-image: linear-gradient(
        90deg,
        rgba(#fff, 1) 0%,
        rgba(#fff, 1) 82%,
        rgba(#fff, 0) 100%
      );
    }
  }

  @include b-d(md) {
    margin-top: 8px;
  }
}

.table {
  min-width: 700px;
  table-layout: fixed;

  @include b-btw(lg, xl) {
    table-layout: auto;
  }

  .tdSymbol {
    min-width: 100px;
  }

  .tdCollection {
    padding-right: 16px;
  }

  th {
    &:nth-child(1) {
      width: 39px;
    }

    &:nth-child(2) {
      width: 23%;
    }

    &:nth-child(3) {
      width: 15%;
    }

    &:nth-child(6) {
      width: 15%;
    }

    &:nth-child(8) {
      width: 15%;
    }
  }

  td,
  th {
    @include b-d(xxl) {
      padding-top: 12px;
      padding-bottom: 12px;
    }
  }

  @include b-d(lg) {
    --cells-bg: transparent;

    &.cellsFilled {
      --cells-bg: #{$gray-800};
    }

    td,
    th {
      &:nth-child(-n + 2) {
        position: sticky;
        z-index: 10;
        background-color: var(--cells-bg);
        transition: background-color 0.2s;

        // Firefox only
        // https://bugzilla.mozilla.org/show_bug.cgi?id=688556
        /* stylelint-disable-next-line */
        @-moz-document url-prefix() {
          border-bottom: 0;

          &::after {
            position: absolute;
            right: 0;
            bottom: 0;
            left: 0;
            content: '';
            border-bottom: 1px solid $white-10;
          }
        }
      }

      &:first-child {
        left: 0;
        padding-right: 0;
        padding-left: 16px;
      }

      &:nth-child(2) {
        left: 39px;
      }

      &:last-child {
        padding-right: 16px;
      }
    }

    /* stylelint-disable-next-line */
    th {
      &:first-child {
        padding-left: 12px;
      }

      &:last-child {
        padding-right: 14px;
      }

      @include b-d(md) {
        padding-top: 8px;
      }
    }
  }
}

.nftImg {
  width: 40px;
  height: 40px;
  border-radius: 8px;

  @include b-up(xxl) {
    width: 48px;
    height: 48px;
  }
}

.gridCollection {
  display: grid;
  grid-template-columns: auto 1fr;
  column-gap: 12px;
  align-items: center;
  white-space: normal;
}
