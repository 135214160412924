@import '~styles/mixins';

.tokenCard {
  position: relative;
  display: grid;
  grid-template-columns: 1fr auto;
  align-items: center;
  padding: 16px;
  text-transform: capitalize;
  border-radius: 16px;

  &::before {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
    content: '';
    background-color: $gray-900;
    border-radius: inherit;
    transition: transform 0.25s;
  }

  &:hover {
    &::before {
      transform: scale(0.985);
    }
  }
}

.tokenInfo {
  display: grid;
  grid-auto-flow: row;
  gap: 4px;
  text-align: start;

  &_img {
    width: 32px;
    height: 32px;
    margin-bottom: 4px;
    overflow: hidden;
    border-radius: 16px;
    transform: translateY(0);
  }

  &_total {
    color: $white-50;
  }
}
