.nav {
  $this: &;

  // Variant: main
  &-main {
    display: grid;
    row-gap: 8px;

    #{$this}-link {
      background-color: $gray-700;
      border-radius: 8px;

      &:hover {
        background-color: $gray-900;
      }

      &.active {
        display: grid;
        grid-template-columns: auto 1fr;
        align-items: center;
        pointer-events: none;
        background-color: $gray-900;

        span {
          @include text-gradient-primary;
        }

        &::before {
          display: block;
          width: 1px;
          height: 16px;
          margin-left: -12px;
          content: '';
          background: $gradient-primary;
        }
      }
    }
  }

  // Variant: tabs filter
  &-tabs-filter {
    display: grid;
    grid-auto-flow: column;
    column-gap: 4px;

    #{$this}-link {
      position: relative;
      min-width: 58px;
      font-weight: $font-weight-semi-bold;
      text-align: center;
      background-color: $gray-600;
      border-radius: 4px;

      &::before {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        display: block;
        padding: 1px;
        pointer-events: none;
        content: '';
        user-select: none;
        background: $gradient-primary;
        border-radius: inherit;
        opacity: 0;
        mask: linear-gradient(#fff 0, #fff 100%) content-box,
          linear-gradient(#fff 0, #fff 100%) border-box;
        transition: opacity 0.25s;
        mask-composite: exclude;
      }

      &.active {
        background-color: $gray-900;

        &::before {
          opacity: 1;
        }

        > span {
          @include text-gradient-primary;
        }
      }
    }

    #{$this}-item {
      &:first-child #{$this}-link {
        padding-right: 12px;
        border-radius: 20px 4px 4px 20px;
      }

      &:last-child #{$this}-link {
        padding-left: 12px;
        border-radius: 4px 20px 20px 4px;
      }
    }
  }
}
