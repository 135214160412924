.table {
  white-space: nowrap;

  @include b-d(xxl) {
    font-size: 12px;
    line-height: calc(16 / 12);
  }

  > :not(:first-child) {
    border-top: none;
  }

  th {
    padding: $table-cell-padding-y 0.375rem $table-cell-padding-y 0.25rem;
    color: $white-50;
  }

  tr {
    font-weight: $font-weight-semi-bold;
  }

  td,
  th {
    vertical-align: middle;

    &:first-child {
      padding-left: 0;
    }

    &:last-child {
      padding-right: 0;
      text-align: end;
    }
  }
}
