@import '~styles/mixins';

.header {
  position: relative;
  z-index: 1001;
  display: grid;
  grid-template-columns: 1fr auto;
  gap: 8px;
  align-items: center;
}

.logo {
  max-width: 134px;
  height: 100%;
  transition-duration: 0.3s, 0.3s;
  transition-property: max-width, transform;

  &.active {
    max-width: 107px;
    transform: translate(-16px, -16px);
  }
}

.hamb {
  $this: &;

  padding: 8px;
  background-color: $gray-700;
  border-radius: 8px;
  transition-duration: 0.15s, 0.3s, 0.3s;
  transition-property: background-color, padding, transform;

  &:hover {
    background-color: $gray-600;
  }

  @include b-up(lg) {
    display: none;
  }

  &[aria-expanded='true'] {
    padding: 6px;
    transform: translate(16px, -16px);

    #{$this}_icon {
      transform: rotate(45deg);
    }

    #{$this}_line {
      &_top {
        stroke-dashoffset: -24;
      }

      &_middle {
        transform: rotate(90deg);
      }

      &_bottom {
        stroke-dashoffset: -24;
      }
    }
  }

  &_icon {
    width: 24px;
    height: 24px;
    transition: transform 0.3s;
  }

  &_line {
    stroke: $white;
    stroke-dashoffset: 0;
    stroke-linecap: round;
    stroke-width: 1.5;
    transition: stroke-dashoffset 400ms;

    &_top {
      stroke-dasharray: 16 160;
    }

    &_middle {
      stroke-dasharray: 16 160;
      transition: transform 400ms;
      transform-origin: 50%;
    }

    &_bottom {
      stroke-dasharray: 16 160;
    }
  }
}
