.dropdown {
  $this: &;

  &-wrapper {
    display: grid;
    grid-auto-flow: column;
    column-gap: 8px;
    align-items: center;
  }

  &-toggle {
    display: grid;
    grid-auto-flow: column;
    column-gap: 8px;
    align-items: center;
    justify-content: space-between;
    min-width: $dropdown-min-width;
    padding: $dropdown-item-padding-y $dropdown-item-padding-x;
    font-weight: $font-weight-semi-bold;
    border: 1px solid $gray-600;
    border-radius: $dropdown-border-radius;
    transition: none;

    &::after {
      width: 12px;
      height: 12px;
      content: '';
      background-image: url("data:image/svg+xml,%3Csvg fill='none' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12'%3E%3Cpath d='M9.96 4.47 6.7 7.73a1 1 0 0 1-1.4 0L2.04 4.47' stroke='url(%23a)' stroke-width='1.5' stroke-miterlimit='10' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cdefs%3E%3ClinearGradient id='a' x1='2.04' y1='4.47' x2='10.25' y2='5.38' gradientUnits='userSpaceOnUse'%3E%3Cstop stop-color='%23C4FDA7'/%3E%3Cstop offset='1' stop-color='%23A6FCF0'/%3E%3C/linearGradient%3E%3C/defs%3E%3C/svg%3E%0A");
      background-size: cover;
      transition: transform 0.25s;
    }

    &[aria-expanded='true'] {
      background-color: $dropdown-dark-bg;
      border-radius: $dropdown-border-radius $dropdown-border-radius 0 0;

      &::after {
        transform: rotateX(180deg);
      }
    }

    &:active,
    &:focus,
    &:active:focus {
      box-shadow: none;
    }
  }

  &-menu-dark {
    overflow: hidden;
    border-radius: 0 0 $dropdown-border-radius $dropdown-border-radius;

    #{$this}-item {
      font-weight: $font-weight-semi-bold;
      border-top: 1px solid $white-10;
    }
  }
}
