@import '~styles/mixins';

.sectionHeader {
  display: grid;
  grid-auto-flow: row;
  gap: 8px;
}

.title {
  @include b-d(md) {
    font-size: 30px;
    font-weight: $font-weight-bold;
    line-height: calc(42 / 30);
  }
}

.leadText {
  color: $white-50;

  @include b-d(md) {
    max-width: 280px;
    font-size: inherit;
    line-height: inherit;
  }
}

.filtersWrapper {
  display: grid;
  grid-template-columns: 1fr auto;
  align-items: center;

  @include b-d(md) {
    align-items: start;
  }
}

.desktopFilters {
  display: grid;
  grid-auto-flow: column;
  column-gap: 24px;
}

.leadPlaceholder {
  display: grid;
  height: 20px;
  padding: 1px 0;

  @include b-d(md) {
    grid-auto-flow: row;
    row-gap: 8px;
    height: 40px;
    padding: 2px 0;
  }
}
