.accordion {
  $this: &;

  display: grid;
  grid-auto-rows: min-content;
  row-gap: 8px;

  &-item {
    background-color: $gray-800;
    border-radius: 8px;
  }

  &-header {
    &.active {
      #{$this}-button {
        color: $gray-900;
        background: $gradient-primary;

        &::after {
          background-image: url("data:image/svg+xml,%3Csvg fill='none' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath d='m13.28 5.967-4.346 4.346a1.324 1.324 0 0 1-1.867 0L2.72 5.967' stroke='%23111213' stroke-width='1.5' stroke-miterlimit='10' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
        }
      }
    }
  }

  &-button {
    display: grid;
    grid-template-columns: auto 1fr auto;
    gap: 8px;
    padding: 12px 16px;
    font-weight: 500;
    border-radius: 8px;

    &:hover {
      background-color: $gray-800;
    }

    @include b-d(lg) {
      background-color: $gray-800;
    }
  }

  &-body {
    padding: 16px 8px 8px;
    border-bottom-right-radius: 8px;
    border-bottom-left-radius: 8px;
  }
}
