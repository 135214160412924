html,
body {
  -webkit-overflow-scrolling: touch;
}

body {
  --background-position-x: center;

  background-image: url('~img/body-bg.png'),
    linear-gradient(115.11deg, #2a2d31 -8.82%, #1c1e21 107.82%);
  background-repeat: no-repeat;
  background-position: center top, center;
  background-size: 1008px 500px, cover;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  @include b-up(lg) {
    background-position: 70% top, center;
  }
}

main {
  min-width: 0;
}

// Styled scrollbar
::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

::-webkit-scrollbar-thumb {
  background: $primary;
  border-radius: 5px;

  &:hover {
    background: darken($primary, 10);
  }
}

::selection {
  background: rgba($primary, 0.3);
}

[type='search']::-webkit-search-decoration,
[type='search']::-webkit-search-cancel-button,
[type='search']::-webkit-search-results-button,
[type='search']::-webkit-search-results-decoration {
  appearance: none;
}

a {
  transition: color 200ms;
}

.js-off-canvas-overlay {
  -webkit-tap-highlight-color: transparent;
}

img {
  max-width: 100%;
}

svg {
  display: block;
}

ol,
ul,
dl {
  padding: 0;
  margin: 0;
}
