@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/mixins';
@import 'settings';

@mixin b-up($name) {
  @include media-breakpoint-up($name) {
    @content;
  }
}

@mixin b-d($name) {
  @include media-breakpoint-down($name) {
    @content;
  }
}

@mixin b-btw($lower, $upper) {
  @include media-breakpoint-between($lower, $upper) {
    @content;
  }
}

@mixin b-o($name) {
  @include media-breakpoint-only($name) {
    @content;
  }
}

@mixin text-gradient-primary {
  color: transparent;
  background: $gradient-primary;
  background-clip: text;
}

@mixin hideScrollbar {
  // https://blogs.msdn.microsoft.com/kurlak/2013/11/03/hiding-vertical-scrollbars-with-pure-css-in-chrome-ie-6-firefox-opera-and-safari/
  // There is a CSS rule that can hide scrollbars in Webkit-based browsers (Chrome and Safari).
  &::-webkit-scrollbar {
    width: 0 !important;
    height: 0 !important;
  }

  &::-webkit-scrollbar-thumb {
    width: 0 !important;
    height: 0 !important;
  }

  &::-webkit-scrollbar-track {
    width: 0 !important;
    height: 0 !important;
  }

  // There is a CSS rule that can hide scrollbars in IE 10+.
  -ms-overflow-style: none !important;

  // Use -ms-autohiding-scrollbar if you wish to display on hover.
  // -ms-overflow-style: -ms-autohiding-scrollbar;

  // There used to be a CSS rule that could hide scrollbars in Firefox, but it has since been deprecated.
  scrollbar-width: none !important;
}
