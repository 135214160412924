/* stylelint-disable selector-pseudo-class-no-unknown */
@import '~styles/mixins';

.buttonUpdate {
  display: grid;
  grid-auto-flow: column;
  gap: 8px;
  align-items: center;
  justify-self: end;
  transition: color 0.15s;

  &:hover {
    color: $gray-300;
  }

  .isRefetching {
    :global {
      animation: rotate 1s linear infinite;
    }
  }
}
