/* stylelint-disable scss/operator-no-unspaced, scss/dollar-variable-pattern */
$uMap: (
  latin: (
    U+0000-00FF,
    U+0131,
    U+0152-0153,
    U+02BB-02BC,
    U+02C6,
    U+02DA,
    U+02DC,
    U+2000-206F,
    U+2074,
    U+20AC,
    U+2122,
    U+2191,
    U+2193,
    U+2212,
    U+2215,
    U+FEFF,
    U+FFFD,
  ),
  latin-ext: (
    U+0100-024F,
    U+0259,
    U+1E00-1EFF,
    U+20A0-20AB,
    U+2020,
    U+20AD-20CF,
    U+2113,
    U+2C60-2C7F,
    U+A720-A7FF,
    U+0394,
  ),
);

@use '~@fontsource/urbanist/scss/mixins' as urbanist;

$syne: 'Urbanist';
@include urbanist.fontFace($weight: 400, $fontName: $syne, $unicodeMap: $uMap);
@include urbanist.fontFace($weight: 500, $fontName: $syne, $unicodeMap: $uMap);
@include urbanist.fontFace($weight: 600, $fontName: $syne, $unicodeMap: $uMap);
@include urbanist.fontFace($weight: 700, $fontName: $syne, $unicodeMap: $uMap);
@include urbanist.fontFace($weight: 800, $fontName: $syne, $unicodeMap: $uMap);
