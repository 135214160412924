@import '~styles/mixins';

.btnSettings {
  position: relative;
  padding: 6px 16px;
  line-height: 0;
  background-color: $gray-900;
  border-radius: 100px;

  &::before {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: block;
    padding: 1px;
    pointer-events: none;
    content: '';
    user-select: none;
    background: $gradient-primary;
    border-radius: 100px;
    mask: linear-gradient(#fff 0, #fff 100%) content-box,
      linear-gradient(#fff 0, #fff 100%) border-box;
    mask-composite: exclude;
  }
}

.title {
  font-weight: $font-weight-medium;
}

.filterGroup {
  display: grid;
  row-gap: 8px;
}
