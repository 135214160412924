@import '~styles/mixins';

.copyright {
  font-size: 12px;
  line-height: 16px;
  color: $gray-600;
  text-align: center;

  a {
    color: inherit;
    text-decoration: underline;

    &:hover {
      text-decoration: none;
    }
  }
}
