@import '~styles/mixins';

.navBar {
  min-height: 72px;
  padding: 16px;
  background-color: $gray-900;
  border-radius: 14px;
  transition: padding 0.3s;

  @include b-up(xxl) {
    min-height: calc(
      100vh - #{$container-padding-top-xxl} - #{$container-padding-bottom-xxl}
    );
  }

  @include b-btw(lg, xxl) {
    min-height: calc(
      100vh - #{$container-padding-top-lg} - #{$container-padding-bottom-lg}
    );
  }

  @include b-up(lg) {
    display: grid;
    grid-template-rows: auto 1fr auto;
    gap: 16px;
    align-self: start;
  }

  @include b-d(sm) {
    padding: 12px;
  }
}
