@import '~styles/mixins';

.table {
  margin-top: 8px;
  font-size: inherit;
  line-height: inherit;

  @include b-btw(md, xxl) {
    margin-top: 16px;
  }

  th {
    &:first-child {
      width: 33.33%;
    }

    &:nth-child(2) {
      width: 33.33%;
    }

    &:nth-child(3) {
      width: 33.33%;
    }
  }

  /* stylelint-disable-next-line */
  td {
    padding-right: 4px;
    padding-left: 4px;

    &:first-child {
      padding-left: 0;
      line-height: 0;
    }

    &:last-child {
      padding-right: 0;
    }
  }
}

.colWithImg {
  display: inline-grid;
  grid-template-columns: 24px auto;
  column-gap: 8px;
  align-items: center;
  white-space: normal;
}

.tokenImgPlaceholder {
  width: 24px;
  height: 24px;
  border-radius: 4px;
}
