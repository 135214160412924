.colImg {
  display: grid;
  grid-auto-flow: column;
  column-gap: 8px;
  align-items: center;
  justify-content: start;
}

.img {
  width: 24px;
  height: 24px;
  border-radius: 4px;
}
