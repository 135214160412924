@import '~styles/mixins';

.sectionWalletEvaluation {
  display: grid;
  grid-template-rows: auto 1fr;
  row-gap: 24px;
  height: 100%;
}

.chartContainer {
  width: 100%;
  min-width: 0;
  max-width: 1096px;
  height: 100%;
  min-height: 450px;
  max-height: 620px;
  background-color: $gray-900;
  border-radius: 16px;
  transform: translateZ(0);

  @include b-btw(xxl, lg) {
    max-height: 600px;
  }

  @include b-d(md) {
    min-height: 300px;
    max-height: 400px;
    overflow: hidden !important;
  }
}
