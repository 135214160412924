@import '~styles/mixins.scss';

.tokensWrapper {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 16px;
  margin-top: 24px;

  @include b-d(xxl) {
    grid-template-columns: repeat(3, 1fr);
  }

  @include b-d(md) {
    grid-template-columns: 1fr;
    gap: 8px;
    margin-top: 16px;
  }
}
