@import '~styles/mixins';

.modal {
  $this: &;

  /* Variant for top menu */
  &-menu {
    z-index: 1000;

    &.fade #{$this}-dialog {
      transform: translate(0, -10px);
    }

    &.show #{$this}-dialog {
      transform: none;
    }

    #{$this}-dialog {
      overflow-y: scroll;
    }
  }

  &-farm {
    #{$this}-dialog {
      height: calc(100% - 100px);
      min-height: 512px;
      padding: 0 16px;
      margin: 50px auto;

      @include b-btw(lg, xxl) {
        height: calc(100% - 60px);
        margin: 30px auto;
      }

      @include b-btw(md, lg) {
        height: calc(100% - 52px);
        margin: 26px auto;
      }

      @include b-d(md) {
        height: calc(100% - 8px);
        margin: 4px auto;
      }
    }

    #{$this}-content {
      justify-content: center;
      max-height: 100%;
      background-color: transparent;
    }

    #{$this}-body {
      background-color: $gray-900;
      border: 1px solid $white-10;
      border-radius: 0.875rem;
    }
  }
}
